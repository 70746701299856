

footer {
    flex: 0 0 auto;
}

footer {
    background-color: #000000;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
    text-align: left;
    font: normal 16px sans-serif;
    padding: 45px 50px;
}

    footer .footer-limiter {
        max-width: 880px;
        margin: 0 auto;
    }

    footer .footer-left p {
        color: #ffffff;
        font-size: 14px;
        margin: 0;
    }

    /* Footer links */

    footer p.footer-links {
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        margin: 0 0 10px;
        padding: 0;
    }

        footer p.footer-links a {
            display: inline-block;
            line-height: 1.8;
            text-decoration: none;
            color: inherit;
        }

    footer .footer-right {
        float: right;
        margin-top: 6px;
        max-width: 180px;
    }

        footer .footer-right a {
            display: inline-block;
            width: 35px;
            height: 35px;
            background-color: #33383b;
            border-radius: 2px;
            font-size: 20px;
            color: #ffffff;
            text-align: center;
            line-height: 35px;
            margin-left: 3px;
        }

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 600px) {

    footer .footer-left,
    footer .footer-right {
        text-align: center;
    }

    footer .footer-right {
        float: none;
        margin: 0 auto 20px;
    }

    footer .footer-left p.footer-links {
        line-height: 1.8;
    }
}





